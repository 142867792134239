import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo'
import Layout from '../components/layout'
import SectionTemplates from '../components/sectionTemplates'

const Filter = ({
  data: {
    allFile: { nodes },
  },
  pageContext: { slug, filter },
}) => {
  return (
    <Layout currentFilterSlug={slug}>
      <Seo title={filter.title} description={filter.description} />
      <SectionTemplates
        title={filter.title}
        subtitle={filter.subtitle}
        tags={filter.tags}
        nodes={nodes}
      />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String) {
    allFile(
      filter: {
        sourceInstanceName: { in: ["templates"] }
        extension: { eq: "mdx" }
        childMdx: { frontmatter: { filters: { eq: $slug } } }
      }
      sort: { fields: childMdx___frontmatter___order }
    ) {
      nodes {
        id
        childMdx {
          frontmatter {
            filter
            slug
            name
            tech
            title
            subtitle
            subtitle2
            longTitle
            listTitle
            icons
            tags
            isFree
            updated(formatString: "MMMM D, YYYY")
            screenshot {
              childImageSharp {
                gatsbyImageData
              }
            }
            screenshots {
              childImageSharp {
                gatsbyImageData
                id
              }
            }
            hasMultipleColors
          }
        }
      }
    }
  }
`

export default Filter
